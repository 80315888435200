
import { defineComponent, ref, reactive, UnwrapRef, computed } from "vue";
import { useRequest } from "vue-request";
import { useRouter } from "vue-router";
import { useAuth } from "@/_modules/auth";
import { authService, accountService } from "@/_services";
import { ISignInPayload, IUser } from "@/_utils/types";
import AlertModal from "@/components/AlertModal.vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  components: {
    AlertModal,
    ExclamationCircleOutlined,
  },
  setup() {
    const router = useRouter();
    const visibleModal = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const alertMessage = ref<string>("");
    const { setAccount, setToken, setFeatureTokens } = useAuth();
    const signInFormRef = ref();
    const signInFormState: UnwrapRef<ISignInPayload> = reactive({
      account_email: "",
      account_password: "",
    });
    const signInRules = {
      account_email: [
        {
          required: true,
          message: "Please input your e-mail address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input an e-mail address format",
          trigger: "blur",
        },
      ],
      account_password: [
        {
          required: true,
          message: "Please input your password",
          trigger: "blur",
        },
        {
          min: 6,
          message: "At least 6 characters",
          trigger: 'submit'
        }
      ],
    };
    const { run, data: loginResult, error } = useRequest(
      authService.login,
      {
        manual: true,
        onError: () => {
          loading.value = false
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const e = error.value as any;
          const { statusCode } = e;
          if (!statusCode) {
            return;
          }
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          if (statusCode && statusCode === 401) {
            visibleModal.value = true;
            alertMessage.value = `Username or Password\nincorrect`;
          }
        },
        onSuccess: (loginResult) => {
          const { data } = loginResult
          const { token } = data as IUser
          setToken(token ?? null)
          setFeatureTokens({
            bigData: data?.account.account_roles.find(e => e.role_featured === 'BIGDATA')?.token?.access_token ?? null,
            academy: data?.account.account_roles.find(e => e.role_featured === 'ACADEMY')?.token?.access_token ?? null,
            eLerning: data?.account.account_roles.find(e => e.role_featured === 'ELEARNING')?.token?.access_token ?? null,
          })
        },
      }
    );
    const { data } = useRequest(accountService.getRolePermission,
      {
        ready: computed(() => !!loginResult.value),
        onSuccess: () => {
          location.reload();
          loading.value = false
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const datas: any = data.value?.data
        //   const { account, account_role } = data.value?.data as any
          setAccount({ ...datas.account, account_roles: datas.account_role });
          router.replace({ name: "home" });
        },
      }
    );
    const handleSignInFinish = (values: ISignInPayload) => {
      loading.value = true
      run({ ...values });
    };

    return {
      signInFormRef,
      signInRules,
      signInFormState,
      handleSignInFinish,
      loading,
      visibleModal,
      alertMessage,
    };
  },
})
