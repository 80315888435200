
import { defineComponent, reactive, watch, computed } from "vue";
import { useRequest } from "vue-request";
import IconSVG from "@/components/IconSVG.vue";
import { useAuth } from "@/_modules/auth"
import { IAccount } from "@/_utils/types";
import { appService } from "@/_services"

interface IENV {
  bigData: boolean
  academy: boolean
  eLearning: boolean
}

export default defineComponent({
	name: "Home",
	components: {
		IconSVG
	},
	setup() {
		// const { VUE_APP_FEATURE_BIGDATA, VUE_APP_FEATURE_ELEARNING, VUE_APP_FEATURE_ACADEMY } = process.env
		const { token, account, featureTokens } = useAuth()
		const { data: result, loading } = useRequest(appService.getFeatures, {ready: computed(() => !!token.value)})
		// const roles = reactive<Roles>({
		// 	bigData: null,
		// 	academy: null,
		// 	eLearning: null
		// })
		const env = reactive<IENV>({
			bigData: false,
			academy: false,
			eLearning: false
		});
		watch([loading], ()=>{
			if(!loading.value && result.value){
				const { data } = result.value
				console.log('loading features', data?.feature)
				env.bigData = ["true", true].includes(data?.feature.FEATURE_BIGDATA ?? false)
				env.academy = ["true", true].includes(data?.feature.FEATURE_ACADEMY ?? false)
				env.eLearning = ["true", true].includes(data?.feature.FEATURE_ELEARNING ?? false)
			}
		})
		/* watchEffect(() => {
			const currentAccount = account.value ?? null
			let { bigData, academy, eLearning } = toRefs(roles)
			bigData.value = currentAccount?.account_roles.find(ele => ele.role_featured === 'BIGDATA')?.roles_permission ?? null
			academy.value = currentAccount?.account_roles.find(ele => ele.role_featured === 'ACADEMY')?.roles_permission ?? null
			eLearning.value = currentAccount?.account_roles.find(ele => ele.role_featured === 'ELEARNING')?.roles_permission ?? null

			env.bigData = VUE_APP_FEATURE_BIGDATA === 'true' && !!bigData.value?.permissions
			env.academy = VUE_APP_FEATURE_ACADEMY === 'true' && !!academy.value?.permissions
			env.eLearning = VUE_APP_FEATURE_ELEARNING === 'true' && !!eLearning.value?.permissions
		}) */
		const goto = (type: string) => {
			const { account_roles } = account.value as IAccount
			switch(type){
				case "bigData": {
					const { roles_permission } = account_roles?.find(ele => ele.role_featured === 'BIGDATA') ?? {}
					const { permissions } = roles_permission ?? {}
					const { permission_code } = permissions ?? {}
					const url = permission_code?.toLowerCase().includes("member") ?
					`${process.env.VUE_APP_EXTERNAL_BIGDATA}`:
					`${process.env.VUE_APP_EXTERNAL_BIGDATA_ADMIN}`;
					const featureToken = featureTokens.value?.bigData
					// window.open('https://sportdataportal.devoncloud.cc/', '_blank')
					window.open(`${url}/sso?token=${featureToken}`, '_blank')
					break
				}
				default:
					alert("URL not provide")
					break
			}
		}
		return {
			env,
			goto
		};
	}
});
